import { ActionPermission } from '@wix/comments-ooi-client';
import { Role } from '../../../services/widget-settings-service';
import { unreachable } from '../../../ts-utils';

export const getActionPermissionStatus = (
  permissionRole?: Role,
  userRole?: 'Visitor' | 'Member' | 'Admin',
): ActionPermission => {
  switch (permissionRole) {
    case Role.MEMBER:
      return userRole === 'Member' || userRole === 'Admin' ? 'AUTO' : 'REQUIRES_LOGIN';
    case Role.ADMIN:
      return userRole === 'Admin' ? 'AUTO' : 'DISABLED';
    case Role.ALL:
      return 'AUTO';
    case Role.UNKNOWN_ROLE:
    case undefined:
      return 'AUTO';
    default:
      throw unreachable(permissionRole);
  }
};
